@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

input,
textarea {
  &:focus {
    outline: none;
  }
}

input::placeholder {
  color: white;
}

textarea::placeholder {
  color: white;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/Montserrat-Light.woff") format("woff"),
    url("./fonts/Montserrat-Light.ttf") format("truetype"), url("./fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/Montserrat-Regular.woff") format("woff"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype"), url("./fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("./fonts/Montserrat-SemiBold.woff") format("woff"),
    url("./fonts/Montserrat-SemiBold.ttf") format("truetype"), url("./fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/Montserrat-Bold.woff") format("woff"),
    url("./fonts/Montserrat-Bold.ttf") format("truetype"), url("./fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
}
